let origin = window.ORIGIN;
//获取活动列表
const getActivityListUrl = `${origin}/gateway/hc-serve/miniapi/activity/activityList`;
//获取活动详情
const getActivityDetailUrl = `${origin}/gateway/hc-serve/miniapi/activity/activityDetail`;
//新增参与人
const addPeopleUrl = `${origin}/gateway/hc-serve/miniapi/activity/myApplicantsAdd`;
// 编辑参与人
const editPeopleUrl = `${origin}/gateway/hc-serve/miniapi/activity/myApplicantsEdit`;
//我的活动参与人列表
const addPeopleListUrl = `${origin}/gateway/hc-serve/miniapi/activity/myActivityApplicantsList`;
//我的活动参与人详情
const addPeopleDetailUrl = `${origin}/gateway/hc-serve/miniapi/activity/myActivityApplicantsDetail`;
//删除活动参与人
const deletePeopleUrl = `${origin}/gateway/hc-serve/miniapi/activity/myActivityApplicantsDelete`;
//活动报名
const applyActivityUrl = `${origin}/gateway/hc-serve/miniapi/activity/activityApply`;
// 取消报名
const cancelActivityUrl = `${origin}/gateway/hc-serve/miniapi/activity/cancelActivityApply`;
// 我报名的活动详情
const activityDetailUrl = `${origin}/gateway/hc-serve/miniapi/activity/myActivityDetail`;
//我的报名
const myActivityUrl = `${origin}/gateway/hc-serve/miniapi/activity/myActivityList`;
//发布活动共
const publishActivityUrl = `${origin}/gateway/hc-serve/miniapi/activity/postActivity`;
//发布的活动列表

const myPublishActivityUrl = `${origin}/gateway/hc-serve/miniapi/activity/myActivityApplyList`;
// 新接口 参与活动
// const applyActivityUrl = `${origin}/gateway/hc-serve/miniapi/activity/v1/activityApply`;

//场所列表
const areaListUrl = `${origin}/gateway/hc-serve/miniapi/activity/reservationPlaceList`;

//个人信息
const userInfoUrl = `${origin}/gateway/hc-mini/user/mini/user-info`;

/** 时间银行 */
// 验证志愿者身份接口
const volunteerUrl = `${origin}/gateway/hc-serve/miniapi/volunteer/verificationVolunteer`;
// 申请志愿者接口
const appliyVolunteerUrl = `${origin}/gateway/hc-serve/miniapi/volunteer/volunteerApplication`;
// 承诺书详情接口
const getCommitmentLetterDetailsUrl = `${origin}/gateway/hc-serve/miniapi/volunteer/commitmentLetterDetails`;
// 是否签署承诺书
const ifCommitmentLetterUrl = `${origin}/gateway/hc-serve/miniapi/volunteer/ifCommitmentLetter`;
// 签署承诺书接口
const applyForCommitmentLetterUrl = `${origin}/gateway/hc-serve/miniapi/volunteer/applyForCommitmentLetter`;
// 我的家庭成员列表接口
const getMyFamilyMemberListUrl = `${origin}/gateway/hc-serve/miniapi/volunteer/myFamilyMemberList`;
// 添加家庭成员
const addFamilyMemberUrl = `${origin}/gateway/hc-serve/miniapi/volunteer/addFamilyMember`;
// 删除家庭成员接口
const deleteFamilyMemberUrl = `${origin}/gateway/hc-serve/miniapi/volunteer/deleteFamilyMember`;

// 参与人备注编辑接口
const compileApplicantsNoteUrl = `${origin}/gateway/hc-serve/miniapi/activity/compileApplicantsNote`;
// 参与人备注详情接口
const applicantsNoteDetailsUrl = `${origin}/gateway/hc-serve/miniapi/activity/applicantsNoteDetails`;

//验证志愿者身份，是否签署承诺书
const judgeVolunteerUrl = `${origin}/gateway/hc-serve/miniapi/volunteer/ifVolunteerAndCommitmentLetter`;
// 进行评论
const addCommentURL = `${origin}/gateway/hc-neighbour/topic/addComment`;
// 话题评论列表分页查询
const getTopicCommentList = `${origin}/gateway/hc-neighbour/topic/getTopicCommentList`; // 进行评论
// 点赞
const addPraiseURL = `${origin}/gateway/hc-neighbour/topic/addPraise`;
// 取消点赞
const cancelPraiseURL = `${origin}/gateway/hc-neighbour/topic/cancelPraise`;
// 获取积分
const getPoints = `${origin}/gateway/hc-accumulated/pointProcess/doPointProcess`;
export {
  getActivityListUrl,
  getActivityDetailUrl,
  addPeopleUrl,
  editPeopleUrl,
  addPeopleListUrl,
  addPeopleDetailUrl,
  deletePeopleUrl,
  applyActivityUrl,
  cancelActivityUrl,
  activityDetailUrl,
  myActivityUrl,
  publishActivityUrl,
  myPublishActivityUrl,
  userInfoUrl,
  areaListUrl,
  volunteerUrl,
  appliyVolunteerUrl,
  getCommitmentLetterDetailsUrl,
  ifCommitmentLetterUrl,
  applyForCommitmentLetterUrl,
  getMyFamilyMemberListUrl,
  addFamilyMemberUrl,
  deleteFamilyMemberUrl,
  compileApplicantsNoteUrl,
  applicantsNoteDetailsUrl,
  judgeVolunteerUrl,
  addCommentURL,
  getTopicCommentList,
  addPraiseURL,
  cancelPraiseURL,
  getPoints,
};
